<template>
  <div class="creactWork">
    <div class="goBack">
      <el-image
        @click="onToBack"
        :src="require(`@/assets/image/blueBack.png`)"
        class="backImg"
      ></el-image>
    </div>
    <el-row class="workForm">
      <el-col :span="24">
        <el-input placeholder="请输入作业名" v-model="entrepot_name"></el-input>
      </el-col>
      <el-col :span="24" class="scoringMechanism">
        <span>评分机制</span>
        <el-radio v-model="score_type" label="1">百分制</el-radio>
        <el-radio v-model="score_type" label="2">自定义</el-radio>
      </el-col>
      <el-col :span="24">
        <el-row type="flex" justify="space-between">
          <el-col :span="8" class="workTitleList">
            <el-row>
              <el-col :span="24" class="totalText"
                >题量{{ totalQuantity }}，总分{{ totalPoints }}</el-col
              >
              <el-col
                :span="24"
                v-for="item in data"
                :key="item.type_order + item.data_type"
              >
                <h4 v-show="item.data_title" class="topicTitle">
                  {{ item.data_type }}(共{{
                    typeTotalObj[item.data_type] &&
                    typeTotalObj[item.data_type].num
                  }}题，{{
                    typeTotalObj[item.data_type] &&
                    typeTotalObj[item.data_type].total
                  }}分)
                </h4>
                <div
                  v-show="item.type_order > 0"
                  class="topic"
                  @click="onCheckedItem(item)"
                >
                  <div>{{ item.type_order }}、</div>
                  <div class="questionText">{{ item.data_questionText }}</div>
                  <div>({{ item.data_score }})分</div>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="16">
            <div class="workType">
              <p>选择题型</p>
              <el-radio-group
                v-model="checkProblem.data_type"
                size="medium"
                @change="onDataTypeChange"
              >
                <el-radio-button
                  v-for="item in [
                    '单选题',
                    '多选题',
                    '填空题',
                    '判断题',
                    '简答题',
                  ]"
                  :key="item"
                  :label="item"
                  class="navBtn"
                  size="small"
                  round
                ></el-radio-button>
              </el-radio-group>
            </div>
            <el-row class="workContent">
              <el-col :span="24" class="workOrder">
                <span>{{ checkProblem.type_order }}</span>
                <span>{{ checkProblem.data_type }}</span>
                <el-input-number
                  :min="1"
                  v-model="checkProblem.data_score"
                  class="score"
                ></el-input-number>
                <span>分</span>
              </el-col>
              <el-col :span="24" class="richText">
                <Toolbar
                  style="border-bottom: 1px solid #ccc"
                  :editor="editorQuestion"
                  :defaultConfig="toolbarConfig"
                  :mode="mode"
                />
                <Editor
                  style="height: 200px; overflow-y: hidden"
                  v-model="checkProblem.data_question"
                  :defaultConfig="editorQuestionConfig"
                  :mode="mode"
                  @onCreated="onQuestionCreated"
                />
              </el-col>
              <el-col :span="24">
                <el-row>
                  <el-col
                    :span="24"
                    v-show="
                      checkProblem.data_type !== '填空题' &&
                      checkProblem.data_type !== '简答题'
                    "
                  >
                    <div
                      v-for="item in checkProblem.option"
                      :key="item.option_order"
                      class="optionOrderItem"
                    >
                      <div
                        :class="`optionTitle ${
                          item.is_correct ? 'checkedOption' : null
                        }`"
                        @click="handleCheckOption(item)"
                      >
                        {{
                          checkProblem.data_type === "判断题"
                            ? { 1: "对", 2: "错" }[item.option_order]
                            : ORDERTYPE[item.option_order]
                        }}
                      </div>
                      <el-input
                        class="singleChoiceInput"
                        v-model="item.option_content"
                      ></el-input>
                      <div
                        class="iconClose"
                        @click="onDeleteOption(item)"
                        v-show="checkProblem.data_type !== '判断题'"
                      >
                        <i class="el-icon-close"></i>
                      </div>
                    </div>
                    <div
                      class="addOption"
                      @click="addOption"
                      v-show="
                        checkProblem.data_type === '单选题' ||
                        checkProblem.data_type === '多选题'
                      "
                    >
                      <i class="el-icon-plus">添加选项</i>
                    </div>
                  </el-col>
                  <el-col
                    v-show="
                      checkProblem.data_type === '填空题' ||
                      checkProblem.data_type === '简答题'
                    "
                    :span="24"
                    class="data_contentText"
                  >
                    <el-input
                      :autosize="{ minRows: 2 }"
                      type="textarea"
                      v-model="checkProblem.data_content"
                      placeholder="请输入答案"
                    ></el-input>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="24" class="richText">
                <Toolbar
                  style="border-bottom: 1px solid #ccc"
                  :editor="editorAnalysis"
                  :defaultConfig="toolbarConfig"
                  :mode="mode"
                />
                <Editor
                  style="height: 200px; overflow-y: hidden"
                  v-model="checkProblem.data_analysis"
                  :defaultConfig="editorAnalysisConfig"
                  :mode="mode"
                  @onCreated="onAnalysisCreated"
                />
              </el-col>
              <el-col :span="24" class="saveData">
                <el-button type="primary" @click="onSaveData">
                  保存题目
                </el-button>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <div class="bottom">
      <el-button class="submitWork" type="primary" @click="onSubmitWork">
        提交作业
      </el-button>
    </div>
  </div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { creactWork, getEntrepotDetail } from "@/request/api";
export default {
  components: { Editor, Toolbar },
  data() {
    return {
      RADIOTYPE: {
        单选题: 1,
        多选题: 2,
        填空题: 3,
        判断题: 4,
        简答题: 5,
      },
      REVERSERADIOTYPE: {
        1: "单选题",
        2: "多选题",
        3: "填空题",
        4: "判断题",
        5: "简答题",
      },
      ORDERTYPE: {
        1: "A",
        2: "B",
        3: "C",
        4: "D",
        5: "E",
        6: "F",
        7: "G",
      },
      entrepot_name: "", // 作业名
      score_type: "1", // 评分制度：1为百分制，2为自定义
      data: [], // 题目数据
      checkProblem: {
        type_order: 1, // 类型下的题目序号
        data_order: 1, // 总的序号
        data_type: "单选题", // 题目类型
        data_score: 1, // 题目分值
        data_question: "", // 题目内容
        data_questionText: "", //展示的题目内容
        option: [], // 题目选项
        data_analysis: "", // 题目解析
      }, // 当前选中的题目
      editorQuestion: null,
      editorAnalysis: null,
      toolbarConfig: {
        excludeKeys: [
          "insertImage",
          "insertVideo",
          "group-video",
          "group-image",
        ], // 排除掉插入图片和插入视频
      },
      editorQuestionConfig: { placeholder: "请输入问题" },
      editorAnalysisConfig: { placeholder: "答案解析" },
      mode: "default", // or 'simple'
      totalQuantity: 0, // 题目数量
      totalPoints: 0, // 总分
      typeTotalObj: {}, // 每种题型的总分
    };
  },
  watch: {
    // "checkProblem.type_order"(value) {
    //   console.log(value, "watch");
    //   if (isNaN(value.type_order)) {
    //     this.checkProblem.type_order = 1;
    //   }
    // },

    data(value) {
      const orderArr = []; // 题数
      let totalP = 0; // 总分
      let totalQ = 0; // 总题数
      let newTypeTotalObj = {}; // 每种题型的总分
      value.forEach((item, index) => {
        const lastItem = value[index > 0 ? index - 1 : 0];
        if (item.data_score) {
          totalP += item.data_score; // 所有总分
          // 计算每种题型的总分
          if (
            (item.data_type === lastItem.data_type || index === 0) &&
            !item.data_title
          ) {
            newTypeTotalObj[item.data_type] = newTypeTotalObj[item.data_type]
              ? newTypeTotalObj[item.data_type]
              : { total: 0, num: 0 };
            newTypeTotalObj[item.data_type].total =
              newTypeTotalObj[item.data_type].total + item.data_score;
            newTypeTotalObj[item.data_type].num =
              newTypeTotalObj[item.data_type].num + 1;
          }
        }
        if (item.type_order > 0) {
          totalQ += 1;
        }
        if (item.data_type === this.checkProblem.data_type) {
          orderArr.push(item.type_order);
        }
        // 插入每种类型的标题
        if (item.type_order === 1) {
          if (!lastItem.data_title) {
            this.data.splice(index > 0 ? index : 0, 0, {
              data_type: this.checkProblem.data_type,
              type_order: 0,
              data_title: true,
            });
          }
        }
      });
      this.totalPoints = totalP;
      this.totalQuantity = totalQ;
      this.typeTotalObj = newTypeTotalObj;
      this.checkProblem = {
        ...this.checkProblem,
        type_order: orderArr[orderArr.length - 1] + 1,
      };
    },
  },
  methods: {
    async onSubmitWork() {
      let isOption = true;
      const newData = [...this.data];
      const body = {
        data: newData
          .map((item) => {
            const newItem = { ...item };
            if (newItem.data_title) {
              return null;
            }
            if (typeof newItem.data_type === "string") {
              newItem.data_type = this.RADIOTYPE[newItem.data_type];
            }
            if (
              (newItem.data_type === 1 || newItem.data_type === 2) &&
              newItem.option.length < 1
            ) {
              isOption = false;
            }
            if (newItem.data_type === 3 || newItem.data_type === 5) {
              delete newItem.option;
            }
            return newItem;
          })
          .filter(Boolean),
        entrepot_name: this.entrepot_name,
        score_type: this.score_type,
        isNew: true,
      };

      if (!body.entrepot_name) {
        this.$message.warning("请先填写作业名");
        return;
      }
      if (this.score_type === "1" && this.totalPoints > 100) {
        this.$message.warning("请保证总分在100或者100一下");
        return;
      }
      if (!isOption) {
        this.$message.warning("请先添加选择题选项");
        return;
      }
      if (body.data.length === 0) {
        this.$message.warning("请先添加题目");
        return;
      }
      if (this.$route.query.shop_user_entrepot_id) {
        body.shop_user_entrepot_id = this.$route.query.shop_user_entrepot_id;
      }
      const res = await creactWork(body);
      if (res.data.code === "1000") {
        this.$message.success("提交成功");
        this.$router.back();
      } else {
        this.$message.error(res.data.msg);
      }
    },
    onCheckedItem(item) {
      this.checkProblem = item;
    },
    onDataTypeChange() {
      const orderArr = [];
      this.data.forEach((item) => {
        if (item.data_type === this.checkProblem.data_type) {
          orderArr.push(item.type_order || 0);
        }
      });
      this.checkProblem = {
        ...this.checkProblem,
        data_question: "", // 题目内容
        option: [], // 题目选项
        data_analysis: "", // 题目解析
        type_order:
          orderArr.length === 0 ? 1 : Number(orderArr[orderArr.length - 1]) + 1,
      };
      if (this.checkProblem.data_type === "判断题") {
        this.checkProblem.option = [
          {
            option_order: 1,
            option_content: "",
          },
          {
            option_order: 2,
            option_content: "",
          },
        ];
      }
    },
    //排序方法
    compare(key) {
      return (m, n) => {
        let a = this.RADIOTYPE[m[key]];
        let b = this.RADIOTYPE[n[key]];
        return a - b;
      };
    },
    onSaveData() {
      const result = this.checkProblem.data_question
        .match(/>([^<]+)</g)
        .map((s) => s.slice(1, -1).trim());
      this.checkProblem.data_questionText = result[0];
      let isRepetition = false;
      let allOrder = 1;
      this.data = this.data.map((item) => {
        const oldKey = item.data_type + item.type_order;
        const newKey =
          this.checkProblem.data_type + this.checkProblem.type_order;
        if (oldKey === newKey) {
          isRepetition = true;
          return { ...this.checkProblem };
        }
        if (!item.data_title) {
          allOrder += 1;
        }
        return item;
      });
      if (!isRepetition) {
        (this.checkProblem.data_order = allOrder), // 总的题序
          this.data.push(this.checkProblem);
        this.data.sort(this.compare("data_type"));
      } else {
        this.onDataTypeChange();
      }
    },
    onDeleteOption(item) {
      this.checkProblem.option = this.checkProblem.option
        .map((v) => {
          if (v.option_order === item.option_order) {
            return null;
          } else {
            if (v.option_order > item.option_order) {
              v.option_order = v.option_order - 1;
              return v;
            } else {
              return v;
            }
          }
        })
        .filter(Boolean);
    },
    handleCheckOption(item) {
      if (
        this.checkProblem.data_type === "单选题" ||
        this.checkProblem.data_type === "判断题"
      ) {
        this.checkProblem.option = this.checkProblem.option.map((v) => {
          if (v.option_order === item.option_order) {
            v.is_correct = 1;
          } else {
            v.is_correct = 0;
          }
          return v;
        });
      } else if (this.checkProblem.data_type === "多选题") {
        this.checkProblem.option = this.checkProblem.option.map((v) => {
          if (v.option_order === item.option_order) {
            if (v.is_correct && item.is_correct) {
              v.is_correct = 0;
            } else {
              v.is_correct = 1;
            }
          }
          console.log(v);
          return v;
        });
      }
    },
    addOption() {
      this.checkProblem.option.push({
        option_order: this.checkProblem.option.length + 1,
        option_content: "",
        is_correct: 0,
      });
    },
    onQuestionCreated(editor) {
      this.editorQuestion = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    onAnalysisCreated(editor) {
      this.editorAnalysis = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    beforeDestroy() {
      const editor = this.editor;
      if (editor == null) return;
      editor.destroy(); // 组件销毁时，及时销毁编辑器
    },

    onToBack() {
      this.$router.back();
    },
    async init() {
      if (this.$route.query.shop_user_entrepot_id) {
        const res = await getEntrepotDetail(
          this.$route.query.shop_user_entrepot_id
        );
        if (res.data.code === "1000") {
          this.data = res.data.data.data.map((item) => {
            item.data_type = this.REVERSERADIOTYPE[item.data_type];

            const result = item.data_question
              .match(/>([^<]+)</g)
              .map((s) => s.slice(1, -1).trim());

            item.data_questionText = result[0];
            return item;
          });
          this.entrepot_name = res.data.data.entrepot_name;
          this.score_type = res.data.data.score_type + "";
        }
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
.creactWork {
  width: 100%;
  position: relative;
  .goBack {
    position: absolute;
    top: 0px;
    left: 30px;
    .backImg {
      width: 40px;
      cursor: pointer;
    }
  }
  .bottom {
    width: calc(100% - 200px);
    padding: 20px 0;
    background: #fff;
    display: flex;
    justify-content: end;
    // text-align: end;
    position: fixed;
    bottom: 0;
    box-shadow: 0px -3px 3px 1px rgba(41, 90, 255, 0.16);
    z-index: 99;
    .submitWork {
      margin-right: 50px;
    }
  }
  .workForm {
    width: 80%;
    margin: 30px auto 0px;
    padding-bottom: 80px;
    /deep/ .el-input__inner {
      border-radius: 8px 8px 8px 8px;
      // border: 1px solid #61a0ff;
    }
    .scoringMechanism {
      line-height: 66px;
      background: #fff;
      border-radius: 8px 8px 8px 8px;
      margin: 15px 0;
      span {
        margin: 0px 20px;
        font-weight: bold;
        font-size: 19px;
        color: #000000;
      }
    }
    .workTitleList {
      width: 31%;
      padding: 20px;
      border-radius: 8px 8px 8px 8px;
      background: #fff;
      // margin-right: 20px;
      .totalText {
        font-weight: 400;
        font-size: 14px;
        color: #929292;
      }
      .topicTitle {
        margin: 10px 0;
      }
      .topic {
        font-size: 14px;
        color: #61a0ff;
        font-weight: 400;
        line-height: 24px;
        cursor: pointer;
        display: flex;
      }
      .questionText {
        font-size: 14px;
        width: 70%;
        overflow: hidden;
        text-overflow: ellipsis; //文本溢出显示省略号
        white-space: nowrap; //文本不会换行
      }
    }
    .workType {
      padding: 20px;
      border-radius: 8px 8px 8px 8px;
      background: #fff;
      display: flex;
      margin-bottom: 20px;
      /deep/ .el-radio-button__inner {
        border: 1px solid #409eff;
        border-radius: 10px;
        width: 100px;
        font-size: 15px;
        line-height: 5px;
      }
      .navBtn {
        margin: 10px 20px 10px 0;
      }
      p {
        min-width: 90px;
        margin: 0;
        line-height: 48px;
      }
    }
    .workContent {
      padding: 20px;
      background: #fff;
      border-radius: 8px 8px 8px 8px;
    }
    .workOrder {
      margin-bottom: 20px;
      > span {
        margin-right: 10px;
        font-size: 15px;
      }
      /deep/.el-input-number__increase {
        border-radius: 0 8px 8px 0;
      }
      /deep/.el-input-number__decrease {
        border-radius: 8px 0 0 8px;
      }
    }
    .score {
      // width: 61px;
      // height: 27px;
      font-size: 15px;
      margin: 0 10px;
    }
    .richText {
      border: 1px solid #707070;
      border-radius: 10px;
      padding: 5px;
    }
    .addOption {
      text-align: right;
      color: #61a0ff;
      font-size: 17px;
      cursor: pointer;
      margin: 20px 0;
    }
    .optionOrderItem {
      display: flex;
      margin: 20px 0;
    }
    .optionTitle {
      width: 30px;
      height: 30px;
      font-size: 14px;
      line-height: 30px;
      border-radius: 15px;
      text-align: center;
      cursor: pointer;
      border: 1px solid #707070;
    }
    .checkedOption {
      background: #61a0ff;
      color: #fff;
      border: 1px solid #61a0ff;
    }
    .singleChoiceInput {
      width: 80%;
      margin: 0 20px;
    }
    .iconClose {
      font-size: 24px;
      cursor: pointer;
      line-height: 40px;
    }
    .data_contentText {
      margin: 20px 0;
    }
    .saveData {
      font-size: 16px;
      margin: 20px auto;
      /deep/ .el-button {
        display: block;
        margin: 0 auto;
      }
    }
  }
}
</style>
